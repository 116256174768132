  .navbar {
    text-align: center;
    color:white;
    // background-image: linear-gradient(-225deg, #9EFBD3 0%, #57E9F2 48%, #45D4FB 100%);
    // background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  }
  .navbar-nav {
    color:white;
  }
 .center-button {
   display: flex;
   justify-content: center;
 }
  .nav-link a {
      color: white;
  }
  .link-color {
    color: white;
  }

  .navbar-container {
    margin-top: 56px;
  }


  $height: 50px;
  $background: #343a40;
  $color: #fff;
  .tabbar-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    background:#343a40;
    position: fixed;
    height: $height;
    bottom: 0;
    z-index: 10;

    .tabbar {
      width: 100%;
      max-width: 800px;
      background:transparent;
  
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        position: relative;
        z-index: 1;
  
        li {
          flex-grow: 1;

          &.active {
            // background-image: linear-gradient(to top, #9be15d 0%, #00e3ae 100%);
//             background-color: #E4E4E1;
//  background-image: radial-gradient(at top center, rgba(255,255,255,0.03) 0%, rgba(0,0,0,0.03) 100%), linear-gradient(to top, rgba(255,255,255,0.1) 0%, rgba(143,152,157,0.60) 100%);
//    background-blend-mode: normal, multiply;
   background-image: linear-gradient(-225deg, #9EFBD3 0%, #57E9F2 48%, #45D4FB 100%);
            border-radius: 6px;
              svg {
                  opacity: 1;
                  color:white;
              }
          }

          &:first-child {
              a {
                  // border-radius: 6px 0 0 6px;
              }
          }
          &:last-child {
              a {
                  // border-radius: 0 6px 6px 0;
              }
          }
          a {
              cursor: pointer;
              display: block;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: initial;
              user-select: none;
              height: $height;
              // background: $background;
              svg {
                  width: 22px;
                  height: 22px;
                  stroke: none;
                  display: block;
                  // opacity: .3;
                  // transition: transform .4s ease, opacity .4s ease;
                  pointer-events: none;
                  // transform-origin: 50% 50%;
                  will-change: transform, fill;
                  // transform: scale(1) translate3d(0, 0, 0);
                  fill: rgb(148, 147, 147);
                  color: rgb(148, 147, 147);;
              }
              span {
                  position: absolute;
                  display: block;
                  left: 0;
                  right: 0;
                  top: 0;
                  text-align: center;
                  font-size: 8px;
                  font-weight: 500;
                  pointer-events: none;
                  text-transform: uppercase;
                  z-index: -1;
                  border-radius: 4px;
                  letter-spacing: .1em;
                  user-select: none;
                  line-height: 20px;
                  transition: transform .3s cubic-bezier(.9, -.4, .2, 1.2) .6s;
                  background: $background;
                  color: white;
              }
              &.pressed {
                  svg {
                      opacity: 1;
                      transform: translate3d(0, 0, 0) scale(.86);
                  }
                  span {
                      transition-delay: .3s;
                      transform: translate(0, -120%);
                  }
              }
              &.active {
                background: green;
                  svg {
                      opacity: 1;
                      color:white;
                  }
              }
          }
      }
      }
    }
  }
  

 