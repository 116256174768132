.spinner-app-container {
    background: black;
    color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-bottom {
    margin-top: 50px;
}