* {
    box-sizing: border-box;
  }
  
  .circle-container {
    width: 100%;
    height: calc(100vh - 56px);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
       
            background: #000000;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
    position: relative;

    
  }
  .landing-headline {
    position: absolute;
    bottom: 80px;
    color: white;
  }
  .button-login {
    position: absolute;
    top: 80px;
  }
  
  .circles {
    width: 50vmin;
    height: 50vmin;
    position: relative;
    -webkit-perspective: 50000px;
            perspective: 50000px;
    color: #61dafb;
    /* color: black; */
  }
  .circles span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    width: 18.4%;
    height: 18.4%;
    border-radius: 100%;
    background: currentColor;
  }
  .circles div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 2vmin solid;
    border-top: 2vmin solid transparent;
    border-radius: 100%;
  }
  .circles div:nth-child(1) {
    -webkit-animation: anim-1 1s linear infinite;
            animation: anim-1 1s linear infinite;
  }
  .circles div:nth-child(2) {
    -webkit-animation: anim-2 1s linear infinite;
            animation: anim-2 1s linear infinite;
  }
  .circles div:nth-child(3) {
    -webkit-animation: anim-3 1s linear infinite;
            animation: anim-3 1s linear infinite;
  }
  
  @-webkit-keyframes anim-1 {
    from {
      -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  
  @keyframes anim-1 {
    from {
      -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  @-webkit-keyframes anim-2 {
    from {
      -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  @keyframes anim-2 {
    from {
      -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  @-webkit-keyframes anim-3 {
    from {
      -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  @keyframes anim-3 {
    from {
      -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
              transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    }
    to {
      -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
              transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
  }
  