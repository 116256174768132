  .signup-page {
    .login-container {
      padding: 20px;
      min-height: calc(100vh - 56px);
      height: 100%;
      background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
      background-blend-mode: normal, lighten, soft-light;
      .spinner-container {
        text-align: center;
      }
    }
  
    .login-container form {
      margin: 0 auto;
      margin-top: 50px;
      max-width: 400px;
      margin-bottom: 10px;
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  
    .alert {
        margin-top: 10px;
    }
  
    .back-button {
        margin-top: 10px;
    }
  }
  