
.spinner-welcome-container {
    background: black;
      color:white;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .welcomepage-container {
    height: 100%;
    padding-top: 10px;
    min-height: calc(100vh - 56px);
      // height: calc(100vh - 56px);
      // background-color: #333;
    background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
    background-blend-mode: normal, lighten, soft-light;
    color: white; 
  }
  .start-button {
      width: 100%;
      box-shadow: 0 0 0 0 rgba(#55f324, .5);
      animation-name: pulse;
      animation-duration:  1.5s;
      animation-iteration-count: infinite;
  }


  @keyframes pulse {
    0% {
      transform: (scale(.9));
    }
    70% {
       transform: (scale(1));
      box-shadow: 0 0 0 50px rgba(#55f324, 0);
    }
    100% {
      transform:(scale(.9));
      box-shadow: 0 0 0 0 rgba(#55f324, 0);
    }
}