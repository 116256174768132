.burgermap-page {
    background-color: #2a2c2e;
    height: calc(100vh - 56px);
    color: white;
    position: relative;
    text-align: center;

    img {
        max-width: 1000px;
        width: 100%;
        height: auto; 
    }
    
}

.burger-map-container {
    text-align: center;

    .burger-image {
        width: 100%;
        height: auto;
    }
}