.restaurantpage-container {
  height: 100%;
  padding-top: 10px;
        min-height: calc(100vh - 56px);
    // height: calc(100vh - 56px);
    // background-color: #333;
    background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
 background-blend-mode: normal, lighten, soft-light;
    color: white; 
    .restaurant-container {
        padding: 20px;
        
        // min-height: calc(100vh - 56px);
        background-color: #2a2c2e;
        
        .spinner-container {
          text-align: center;
        }

        
      }

      .searchBar {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    
      .alert {
          margin-top: 10px;
      }

      .center {
        text-align: center;
        display: flex;
        justify-content: center;
      }

      .restaurant-card {
        margin: 10px;
        color: black;
        // background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        // background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);
        background-image: linear-gradient(to top, lightgrey 0%, lightgrey 1%, #e0e0e0 26%, #efefef 48%, #d9d9d9 75%, #bcbcbc 100%);
        // background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        position: relative;
        
        .card-title {
          height: 48px;
        }

        .rating-count {
          font-size: 25px;
          margin-bottom: 8px;
        }

        .btn-group {
          width: 100%;
        }
      }     
      
      .restaurant-card:hover {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
      }
}

.spinner-restaurant-container {
  background: black;
    color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}