body {
  margin: 0;
  margin-bottom: 56px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Manrope', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
    /* monospace; */
}

/* .add-button {
  position: absolute;
  top: 1px;
  left: 1px;
} */