.spinner-ratinglist-container {
    background: black;
      color:white;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }


  .ratinglistpage-container {
    height: 100%;
    min-height: calc(100vh - 56px);
      // height: calc(100vh - 56px);
      // background-color: #333;
    //   display: flex;
    //   justify-content: center;
    //   flex-direction: column;
      background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
      background-blend-mode: normal, lighten, soft-light;
      color: white; 
      .ratingPage-Headline {
        text-align: center;
      }
      .button-container{
        width: 100%;
        text-align: center;

        .back-button {
            width: 80%;
            max-width: 400px;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
            z-index: 10;
          }
      }
      
      .alert-container {
        text-align: center;
        max-width: 500px;
        margin: auto;
        padding-top: 20px;
        .alert-heading {
            font-size: 2.5rem;
        }
        .alert-danger {
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
        .alert-image {
            width: 100%;
        }

        .alert-button {
            margin-top: 10px;
            width: 100%;
        }
    }
    .center {
        text-align: center;
        display: flex;
        justify-content: center;
      }
    .rating-card {
        margin: 20px;

    }
    }

    